import React, {useContext} from 'react';
import '../style/App.scss';
import Summary from "../components/Phare/Summary";
import {PositionContext} from "../App";
import {faMapMarkedAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import default_lighthouse from "../images/default.png";
import Distance from "../components/Phare/Distance";
import {faCompass} from "@fortawesome/free-regular-svg-icons";
import {Link} from "react-router-dom";
import Separateur from "./Separateur";
import getConfig from "../tools/config";
import Direction from "./Phare/Direction";
import getClose from "../tools/close";
import getDistance from "../tools/distance";

export default function Closest () {
    const position = useContext(PositionContext);
    const close = getClose(11, position);
    const closest = close.shift();
    const second = close[0];

    return <>
        {position.coords.accuracy > getDistance(closest.lat, closest.lng, second.lat, second.lng) * 1000
            ? <div className={"alert alert-danger"}><FontAwesomeIcon icon={faMapMarkedAlt} /> La précision de votre positionnement est faible ({Math.round(position.coords.accuracy)}m), le résultat pourrait ne pas être fiable</div>
            : ''
        }
        <div className={"hasDarkBackground card"}>
            <div className={"card--content"}>
                <small>Le plus proche de vous :</small>
                <Link to={'/phares/'+closest.id}><h1>{closest.title}</h1></Link>
            </div>
            <div className={'card--image'}>
                <Link to={'/phares/'+closest.id}>
                    <img alt={""} src={closest.illus ? getConfig().imagesPath + closest.illus : default_lighthouse} className={'illus'} />
                </Link>
            </div>
            <div className={"card--content"}>
                <FontAwesomeIcon icon={faCompass} /> <Distance phare={closest}/> <Direction phare={closest} /> <br/>
                <Link to={'/phares/'+closest.id}>Plus d'infos</Link>
            </div>
        </div>
        <Separateur />
        <h2>Un peu plus loin d'ici</h2>
        <div className={"hasLightBackground card"}>
            <div className={"lightHouseMapper"}>
                {close.map(phare => <Summary phare={phare} key={phare.title} />)}
            </div>
        </div>
    </>
}
