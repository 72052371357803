import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as firebase from "firebase/app";
import "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyAlVkOu9IYZ3CQIJGvGBXZ053Tyy4AKYfc",
    authDomain: "phares-fd787.firebaseapp.com",
    databaseURL: "https://phares-fd787.firebaseio.com",
    projectId: "phares-fd787",
    storageBucket: "phares-fd787.appspot.com",
    messagingSenderId: "931446586181",
    appId: "1:931446586181:web:b00abdb984501a9981dc2c",
    measurementId: "G-PPDTTPVERF"
};

firebase.initializeApp(firebaseConfig);
if (process.env.NODE_ENV === 'production') {
    firebase.analytics();
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
