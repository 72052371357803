/*
* From : https://www.geodatasource.com/developers/javascript
* */

export default function getDistance(lat1, lon1, lat2, lon2, unit = "K") {
    if ((lat1 === lat2) && (lon1 === lon2)) {
        return 0;
    }

    const radLat1 = Math.PI * lat1/180;
    const radLat2 = Math.PI * lat2/180;
    const theta = lon1-lon2;
    const radTheta = Math.PI * theta/180;
    let dist = Math.sin(radLat1) * Math.sin(radLat2) + Math.cos(radLat1) * Math.cos(radLat2) * Math.cos(radTheta);
    if (dist > 1) {
        dist = 1;
    }
    dist = Math.acos(dist);
    dist = dist * 180/Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit==="K") { dist = dist * 1.609344 }
    if (unit==="N") { dist = dist * 0.8684 }

    return dist;
}

export function getBearing (lat1, lng1, lat2, lng2) {
    var dLon = (lng2-lng1);
    var y = Math.sin(dLon) * Math.cos(lat2);
    var x = Math.cos(lat1)*Math.sin(lat2) - Math.sin(lat1)*Math.cos(lat2)*Math.cos(dLon);
    var brng = _toDeg(Math.atan2(y, x));
    return 360 - ((brng + 360) % 360);
}

export function getBearing2 (φ1, λ1, φ2, λ2) {
    const y = Math.sin(λ2-λ1) * Math.cos(φ2);
    const x = Math.cos(φ1)*Math.sin(φ2) -
        Math.sin(φ1)*Math.cos(φ2)*Math.cos(λ2-λ1);
    const θ = Math.atan2(y, x);
    return (θ * 180 / Math.PI + 360) % 360;
}

/**
 * Since not all browsers implement this we have our own utility that will
 * convert from radians into degrees
 *
 * @param rad - The radians to be converted into degrees
 * @return degrees
 */
function _toDeg(rad) {
    return rad * 180 / Math.PI;
}