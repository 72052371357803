import React, {useContext, useEffect} from 'react';
import default_lighthouse from "../images/default.png";
import phares from '../phares'
import {Link, useParams} from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import getConfig from "../tools/config";
import {Map, GoogleApiWrapper, Marker} from 'google-maps-react';
import logo from '../images/logo.svg'
import {PositionContext} from "../App";
import TextInfo from "./Phare/TextInfo";
import {faWikipediaW} from "@fortawesome/free-brands-svg-icons";

function Phare (props) {
    const {phareId} = useParams();
    const position = useContext(PositionContext)

    useEffect(() => {
        window.scrollTo(0,0);
    });

    const phare = phares.filter((elt) => elt.id === phareId)[0];

    const mapStyles = {
        width: '100%',
        height: '100%',
    };

    return <div className={'card hasDarkBackground'}>
        <h1>{phare.title}</h1>
        <img
            alt={""}
            src={phare.illus ? getConfig().imagesPath + phare.illus : default_lighthouse}
            className={'illus'}
        />
        <div className={'card--content'}>
            <TextInfo phare={phare}/>
        </div>
        <div style={{position: 'relative', height: 300}}>
            <Map
                className={'card'}
                google={props.google}
                zoom={14}
                style={mapStyles}
                initialCenter={{ lat: phare.lat, lng: phare.lng}}
                center={{ lat: phare.lat, lng: phare.lng}}
            >
                <Marker
                    position={{lat: phare.lat, lng: phare.lng}}
                    name={phare.title}
                    title={phare.title}
                    icon={{
                        url: logo,
                        anchor: new props.google.maps.Point(10,10),
                        scaledSize: new props.google.maps.Size(20,20)
                    }}
                />
                <Marker
                    position={{ lat: position.coords.latitude, lng: position.coords.longitude}}
                    title={"Votre position"}
                />
            </Map>
        </div>
        <a href={phare.url} className={'button button--wiki'} target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faWikipediaW}/> Voir la page Wikipédia</a>
        <Link to={'/'}>Retour à la liste</Link>
    </div>
}

export default GoogleApiWrapper({apiKey: getConfig().googleApiKey})(Phare)