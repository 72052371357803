import React, {useContext} from 'react';
import {PositionContext} from "../../App";
import getDistance from "../../tools/distance";

export default function Distance ({phare}) {
    const position = useContext(PositionContext);
    const rawDistance = getDistance(position.coords.latitude, position.coords.longitude, phare.lat, phare.lng);
    let km = Math.round(rawDistance);
    let meters = null;
    if (km < 3) {
        km = Math.floor(rawDistance);
        meters = Math.round((rawDistance - km)*100) * 10;
    }

    return  <>{km}km {meters && meters + "m"}</>
}