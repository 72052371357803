import React, {useContext} from 'react';
import {PositionContext} from "../../App";
import {getBearing2} from "../../tools/distance";
import getDirection, {DIRECTION_LONG} from "../../tools/direction";

export default function Direction ({phare}) {

    const position = useContext(PositionContext);
    const bearing = getBearing2(position.coords.latitude, position.coords.longitude, phare.lat, phare.lng);
    const sector = getDirection(bearing, DIRECTION_LONG, true);

    //console.log(sector, bearing);

    return  <></>//<>direction {sector}</>
}