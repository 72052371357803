import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCompass} from "@fortawesome/free-regular-svg-icons";
import Distance from "./Distance";
import Direction from "./Direction";
import {Link} from "react-router-dom";
import getConfig from "../../tools/config";
import default_lighthouse from "../../images/default.png";

export default function Summary({phare}) {

    const url = phare.illus ? getConfig().imagesPath + phare.illus : default_lighthouse;

    const style = {
        backgroundImage: 'url('+url+')',
    }

    return <article className={'Phare__mini'} style={style}>
        <Link to={'/phares/'+phare.id}>
            <h3>{phare.title}</h3>
            <FontAwesomeIcon icon={faCompass} /> <Distance phare={phare}/> <Direction phare={phare} />
        </Link>
    </article>
}