import {useContext} from 'react';
import {PositionContext} from "../../App";
import getDistance from "../../tools/distance";

export default function Visibilite ({phare}) {
    const position = useContext(PositionContext);
    const rawDistance = getDistance(position.coords.latitude, position.coords.longitude, phare.lat, phare.lng);
    let km = Math.round(rawDistance);

    if (km > 20) return 'ne devrait pas être visible';
    if (km > 5) return 'pourrait être visible si les conditions le permettent';

    return 'devrait être visible'
}