import React, {Component} from 'react';
import './style/App.scss';
import Phare from "./components/Phare";
import {BrowserRouter, Link, Route, Switch} from "react-router-dom";
import Closest from "./components/Closest";
import Legal from "./components/Legal";
import getConfig from "./tools/config";
import {faCompass} from "@fortawesome/free-regular-svg-icons";
import logo from './images/logo.svg'
import header from './images/header.svg'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHeart} from "@fortawesome/free-solid-svg-icons";
import Separateur from "./components/Separateur";
import All from "./components/All";

export const PositionContext = React.createContext(getConfig().defaultPosition)

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            position: getConfig().defaultPosition,
        };

    }

    componentDidMount() {
        navigator.geolocation.watchPosition((position) => {
            this.setState({position: position})
        }, console.error)
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (this.state.position === null) {
            return true;
        }

        if (this.state.position.coords.accuracy > nextState.position.coords.accuracy) {
            return true;
        }

        if (Math.abs(this.state.alpha - nextState.alpha) > 2) {
            return true;
        }

        if (Number(this.state.position.coords.latitude).toFixed(2) !== Number(nextState.position.coords.latitude).toFixed(2) || Number(this.state.position.coords.longitude).toFixed(2) !== Number(nextState.position.coords.longitude).toFixed(2)) {
            return true;
        }

        return false;
    }

    render() {
        const position = this.state.position;

        if (null === position) {
            return <div className={"splashScreen"}>
                <img src={logo} height={200} alt={'logo'}/>
                <div className={'text'}>Un instant, on cherche où vous êtes</div>
                <FontAwesomeIcon icon={faCompass} className={'loader'}/>
            </div>
        }

        return <PositionContext.Provider value={this.state.position}>
            <BrowserRouter>
                <div className={'container'}>
                    <div className={"header"}>
                        <Link to={'/'}><img src={header} alt={"logo"} className={'logo'} /></Link>
                    </div>
                    <Switch>
                        <Route path={"/mentions-legales"}>
                            <Legal />
                        </Route>
                        <Route path={"/phares/:phareId"}>
                            <Phare />
                        </Route>
                        <Route path={"/tous-les-phares"}>
                            <All />
                        </Route>
                        <Route path={"/"}>
                            <Closest />
                        </Route>
                    </Switch>
                    <Separateur/>
                    <div className={'footer'}>
                        Conçu avec <FontAwesomeIcon icon={faHeart} /> par <a href="https://arnaudbenassy.com">Arnaud Benassy</a><br/>
                        <div className={'footer--links'}>
                            <Link to={'/tous-les-phares'}>Tous les phares de France</Link>
                            <Link to={'/'}>Quel est le phare le plus proche de moi ?</Link>
                            <Link to={'/questions/phare-le-plus-haut'}>Quel est le phare le plus haut de France ?</Link>
                        </div>
                    </div>
                </div>
            </BrowserRouter>
        </PositionContext.Provider>
        ;
    }
}

export default App;
