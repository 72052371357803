import React from 'react';
import Distance from "./Distance";
import Visibilite from "./Visibilite";
import {Link} from "react-router-dom";
import {faWikipediaW} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLink} from "@fortawesome/free-solid-svg-icons";

export default function TextInfo ({phare}) {
    return <>
        <h2>Le {phare.title}</h2>
        {phare.short_presentation
            ? <p dangerouslySetInnerHTML={{__html: phare.short_presentation}}/>
            : <>
                <p>Le {phare.title} {phare.height && ", d'une hauteur de " + phare.height + ", "}{phare.buildDate && "a été construit en " + phare.buildDate}</p>
                <p>Il se trouve à <Distance phare={phare}/> de votre position (coordonnées
                    GPS <small>{Number(phare.lat).toFixed(4)}, {Number(phare.lng).toFixed(4)}</small>), et <Visibilite
                        phare={phare}/>.</p>
                {phare.light &&
                <>
                    <p>
                        Son feu est décrit comme suit : {phare.light}. <a
                        href="https://fr.wikipedia.org/wiki/Feu_maritime" rel="noopener noreferrer" title="Feu maritime"
                        target="_blank"> En apprendre plus sur les feux</a>
                    </p>
                </>}
            </>
        }
        {phare.extra_info && <p>{phare.extra_info}</p>}
        <h2>Peut-on visiter le {phare.title} ?</h2>
        <p>
            { typeof phare.visit === undefined && "Nous de disposons pas pour le moment d'informations sur la possibilité de visiter le " + phare.title}
            { typeof phare.visit !== undefined && phare.visit ? "Oui, l'intérieur du " + phare.title + " peut être visité" : "Non, malheureusement, l'intérieur du " +phare.title+ " ne peut pas être visité pour le moment" }
        </p>
        <h2>Y'a t'il un gardien dans le {phare.title} ?</h2>
        {phare.keeper_info
            ? <p dangerouslySetInnerHTML={{__html: phare.keeper_info}}/>
            : <p>Non, il n'y a plus de gardien dans les phares en France, qui sont tous automatisés depuis septembre 2019. Seule exception : <Link to={'/phares/Phare_de_Cordouan'} className={'visible-link'}><FontAwesomeIcon icon={faLink}/> le Phare de Cordouan</Link></p>
        }
    </>
}