import React, {useEffect, useState, useContext} from 'react';
import '../style/App.scss';
import Summary from "../components/Phare/Summary";
import phares from "../phares.json";
import {PositionContext} from "../App";
import getDistance from "../tools/distance";

export default function All () {
    const [sortMethod, setSortMethod] = useState('alphabetic');
    const [filter, setFilter] = useState(null);
    const position = useContext(PositionContext);

    const alphabeticSort = (a,b) => {
        console.log("alplha")
        return a.title > b.title ? 1 : -1;
    }
    const distanceSort = (a,b) => {
        console.log('distance')
        return getDistance(a.lat, a.lng, position.coords.latitude, position.coords.longitude) > getDistance(b.lat, b.lng, position.coords.latitude, position.coords.longitude) ? 1 : -1;
    }

    const sortMethods = {
        alphabetic: alphabeticSort,
        distance: distanceSort,
    };

    const sortedPhares = phares.sort(sortMethods[sortMethod]);

    const filteredPhares = sortedPhares.filter( elt => {
        if (filter === null) return true;
        return elt.title.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    })

    return <>{sortMethod}
        <h2>Tous les phares en France</h2>
        <input type="text" onChange={e => setFilter(e.target.value)}/>
        <div className={"hasLightBackground card"}>
            <select onChange={(event) => {setSortMethod(event.target.value)}}>
                <option value={'alphabetic'}>Alphabétique</option>
                <option value={'distance'}>Par distance</option>
            </select>
            <div className={"lightHouseMapper"}>
                {
                    filteredPhares.length === 0
                        ? <div>Pas de résultat... Avec une autre orthographe peut-être ?</div>
                        : filteredPhares.map(phare => <Summary phare={phare} key={phare.id} origin={'/tous-les-phares'}/>)
                }
            </div>
        </div>
    </>
}
