import phares from "../phares.json";
import getDistance from "./distance";

export default function getClose(number = 5, position) {
    if (position === null) {
        return [];
    }

    return phares.sort((a, b) => {
        return getDistance(position.coords.latitude, position.coords.longitude, a.lat, a.lng) > getDistance(position.coords.latitude, position.coords.longitude, b.lat, b.lng) ? 1 : -1;
    }).slice(0, number);
}
