export const DIRECTION_LONG = 'long';
export const DIRECTION_SHORT = 'short'

export default function getDirection(bearing, mode = DIRECTION_LONG, approximation = false) {
    let directions = ["Nord","Nord-nord-est","Nord-est","Est-nord-est","Est","Est-sud-est","Sud-est","Sud-sud-est","Sud","Sud-sud-ouest","Sud-ouest","Ouest-sud-ouest","Ouest","Ouest-nord-ouest","Nord-ouest","Nord-nord-ouest","Nord"];
    let sectorSize = 22.5;
     if (mode === DIRECTION_SHORT) {
         directions = ["N","NNE","NE","ENE","E","ESE","SE","SSE","S","SSO","SO","OSO","O","ONO","NO","NNO","N"];
     }
     if (approximation) {
         sectorSize = 45;
         directions = directions.filter( (elt, index) => index % 2 === 0 );
     }

    return directions[Math.round((bearing % 360) / sectorSize)];
}