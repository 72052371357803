
export default function getConfig() {
    let defaultPosition = null;
    if (process.env.REACT_APP_USE_DEFAULT_POSITION === "true") {
        //Maison : 48.260387, -1.611197
        //Pointe du raz : 48.039518, -4.735276
        defaultPosition = {coords:{latitude:48.039518,longitude:-4.735276,accuracy:2000}};
    }

    return {
        defaultPosition,
        imagesPath: process.env.PUBLIC_URL + "/images/phares/full/",
        googleApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    };
};